.itemContainer {
  border-radius: 20px;
  box-shadow: 2px 4px 30px 0px rgba(26, 41, 60, 0.1);
  margin-bottom: 20px;

  &.important {
    border: 1px solid #ff1a56;
  }
}

.topRow {
  border-bottom: 1px solid #ececec;
}

.toggleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 26px;
  height: 27px;
}

.row {
  padding: 8px 11px;
}

.row:first-child {
  padding-top: 15px;
}

.row:last-child {
  padding-bottom: 15px;
}

.idLabel {
  color: #545454;
  font-weight: 600;
}

.id {
  color: #545454;
  font-weight: 700;
}

.name {
  color: #1d1d1f;
  font-weight: 700;
}

.label {
  width: 70px;
}

.value {
  color: #1d1d1f;
  font-weight: 700;
}

.notesLabel {
  margin-bottom: 12px;
}

.notes {
  color: #1d1d1f;
  font-weight: 500;

  p {
    margin-bottom: 0.5rem; // less than default; space is tight here
  }
}

.rotated {
  rotate: 180deg;
}
