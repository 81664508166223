.internalNotes {
  background: var(--background-pink);
  border: 1px solid var(--primary-pink);
  padding: 15px 10px 15px 20px;
  border-radius: 20px;
  font-weight: normal;
  flex: 1 0 auto;
  width: -webkit-fill-available;
}

.scrollableContent {
  overflow-y: scroll;
  height: -webkit-fill-available;
}

.root {
  display: flex;
  gap: 30px;
  height: 100%;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  gap: 20px;
}

.headerButtons {
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
}
.headerButton {
  height: min-content;
}

.mainPage {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sidePanel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 4px 30px 0px rgba(26, 41, 60, 0.1);
  min-width: 300px;
  width: 350px;
}

.panelMainContent {
  padding: 25px;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.accountAccordion {
  border-top: 1px solid var(--light-gray);
}

.accordionDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-bottom: 1px solid var(--light-gray);
}

.accordionLeft {
  display: flex;
  align-items: center;
  gap: 6px;
}

.accordionRight {
  font-weight: 500;
  text-align: right;
}