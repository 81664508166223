.switch {
  position: relative;
  display: inline-block;
  width: 140px;
  height: 34px;
}

.switch:focus {
  border: 2px solid orange;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid var(--primary-blue);
  background: linear-gradient(to left, #fff 50%, var(--primary-blue) 50%) right;
  background-size: 200% 100%;

  -webkit-transition: 0.15s;
  transition: 0.15s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 3px;
  bottom: 3px;
  background-color: var(--primary-blue);
  background-image: url('/assets/images/pencil-bordered-white.svg');
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: center;

  -webkit-transition: 0.15s;
  transition: 0.15s;
}

input:checked + .slider {
  background-color: var(--primary-blue);
  background-position: left;
}

input:checked + .slider:before {
  background-color: #fff;

  -webkit-transform: translateX(105px);
  -ms-transform: translateX(105px);
  transform: translateX(105px);

  background-image: url('/assets/images/pencil-bordered-primary.svg');
}

.on,
.off {
  position: absolute;
  top: 50%;
  left: 50%;
  white-space: nowrap;
  font-weight: bold;
}

.on {
  color: white;
  transform: translate(-65%, -50%);
}

.off {
  color: var(--primary-blue);
  transform: translate(-35%, -50%);
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}
