.filterRow {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--light-gray);
}

.popover {
  border-color: var(--cui-primary);
  font-size: 1rem;
}

.select {
  margin: 0px 10px;
  width: -webkit-fill-available;
}

.input {
  margin: 0px 10px;
  width: -webkit-fill-available;
}