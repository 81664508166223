.buttons {
  gap: 10px;
}

.button {
  width: 127px;
}

.required {
  color: var(--primary-blue);
}

.icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  path {
    stroke: var(--primary-blue);
  }
}

.iconLeft {
  margin-right: 0;
  margin-left: 10px;
}
