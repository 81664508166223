.form {
  border-radius: 20px;
  box-shadow: 0px 4px 30px 0px rgba(26, 41, 60, 0.1);
  padding: 21px;
}

.buttons {
  gap: 10px;
}

.button {
  min-width: 127px;
}

.buttonGroup button:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.buttonGroup button:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.required {
  color: var(--primary-blue);
}
