.resourceCell {
  width: 100%;
}

.headerRow {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nameSection {
  display: flex;
  align-items: center;
  gap: 6px;
}

.infoRow {
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
}

.moreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  width: 28px;
  height: 28px;
}

.moreIcon {
  height: 12px;
  width: 12px;
}