.mainPage {
  width: 100%;
  min-height: 100vh;
}

.sidePanel {
  box-shadow: 2px 4px 30px 0px rgba(26, 41, 60, 0.1);
  width: 350px;
  padding: 20px;
}

.titleRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.healthPlanColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}