// Set CoreUI global variables before CoreUI has been imported. Remove the `!default` flag from the variables you want
// to set.
// https://coreui.io/react/docs/customize/sass/#variable-defaults
$enable-ltr: true;
$enable-rtl: true;

$font-family-base: Gilroy, sans-serif;
$font-size-base: 0.875rem;

$primary: #3267f1;
$primary-rgb: 50, 103, 241;
$danger: #ff1a56;
$danger-rgb: 255, 26, 86;
$success: #42d573;
$success-rgb: 66, 213, 115;
$warning: #f9b114;
$warning-rgb: 249, 177, 20;
$secondary: #8f8f8f;
$secondary-rgb: 143, 143, 143;

$accordion-button-active-color: #1d1d1f;
$accordion-button-active-bg: white;
$accordion-button-focus-border-color: none;
$accordion-button-focus-box-shadow: none;
$accordion-border-color: none;

$btn-font-weight: 700;

$card-border-radius: 20px;
$card-cap-bg: #f5fbff;
$card-box-shadow: 2px 4px 30px 0px rgba(26, 41, 60, 0.1);

$input-disabled-bg: hsl(0, 0%, 95%);
$input-disabled-border-color: hsl(0, 0%, 90%);

$form-multi-select-border-color: #ececec;
$form-multi-select-border-radius: 16px;
$form-multi-select-color: #1f1f1f;
$form-multi-select-disabled-bg: hsl(0, 0%, 95%);
$form-multi-select-disabled-border-color: hsl(0, 0%, 90%);
$form-multi-select-disabled-color: hsl(0, 0%, 60%);
$form-multi-select-focus-box-shadow: none;

$form-select-border-color: #ececec;
$form-select-border-radius: 16px;
$form-select-color: #1f1f1f;
$form-select-disabled-bg: hsl(0, 0%, 95%);
$form-select-disabled-border-color: hsl(0, 0%, 90%);
$form-select-disabled-color: hsl(0, 0%, 60%);
$form-select-focus-box-shadow: none;

$form-input-border-color: #ececec;

$sidebar-bg: #1d1d1f;
$sidebar-nav-link-active-bg: rgba(255, 255, 255, 0.15);
$sidebar-nav-link-border-radius: 12px;
$sidebar-nav-link-color: #ffffff;
$sidebar-brand-height: 4.5rem;
$sidebar-nav-link-padding-y: 0.6rem;
$sidebar-nav-title-color: #B1B1B1;
$sidebar-nav-title-margin-top: 0.5rem;
$sidebar-nav-title-padding-x: 0;
$sidebar-nav-title-padding-y: 0;
$sidebar-nav-group-bg: none;
$sidebar-nav-icon-width: 1rem;

$badge-font-weight: normal;

$headings-font-weight: bold;
$h1-font-size: 1.5rem;
$h2-font-size: 1.25rem;
$h3-font-size: 1rem;

$nav-pills-border-radius: 60px;
$nav-pills-link-active-bg: #fe1c56;
$nav-link-color: #545454;
$nav-link-font-weight: 500;
$nav-underline-link-active-border-color: #fe1c56;
$nav-underline-link-active-color: #fe1c56;
$nav-underline-link-color: #545454;

$pagination-color: #545454;
$pagination-hover-color: #545454;
$pagination-active-bg: #ffffff;
$pagination-active-color: #f64a76;
$pagination-active-border-color: #f64a76;
$pagination-border-radius: 10px;

$popover-max-width: 300px;

$border-radius-sm: 16px;