.form {
  border-radius: 20px;
  box-shadow: 0px 4px 30px 0px rgba(26, 41, 60, 0.1);
  padding: 21px;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.select > div {
  border-color: #ececec;
  border-radius: 16px;
  color: #1f1f1f;
}