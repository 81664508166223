.header {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.appointmentType {
  color: var(--cui-primary);
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
}

.body {
  flex-direction: column;
}

.rows {
  display: flex;
  gap: 1rem;
}

.column {
  width: 50%;
}

.columnWithSelect {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    width: min-content;
  }

  > .value {
    width: 70%;
  }
}

.value {
  font-weight: 600;
  text-align: right;
}

.notes {
  white-space: pre-wrap;
  text-align: left;
}
