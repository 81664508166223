.whiteboard {
  thead tr:first-child > th:first-child {
    padding-left: 0px;

    table {
      width: 100% !important;
    }
  }
  tbody td:first-child {
    padding-left: 0px;

    table {
      width: 100% !important;
    }
  }
}

.eventCell {
  height: 100px;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 10px;
  cursor: pointer;

  border: 2px solid #3267F1;
  background-color: #F5FBFF;
  color: black;
}

.cellSuccess {
  background-color: #E7FBEE;
  border-color: #42D573;
}

.cellWarning {
  border-color: #FF1A56;
  background-color: #ff1a561a;
}