.container {
  border-radius: 20px;
  box-shadow: 2px 4px 30px 0px rgba(26, 41, 60, 0.1);
  padding: 19px;
}

.date {
  flex: 1;
  text-align: end;
}

.label {
  text-align: start;
  width: 50%;
}
