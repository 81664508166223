.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  width: 28px;
  height: 28px;
  border: 1px solid var(--cui-primary);
}

// Override hover state that hides the border
.toggle:hover {
  border: 1px solid var(--cui-primary);
}

.moreIcon {
  height: 12px;
  width: 12px;
}

.dropdownItem {
  display: flex !important;
  align-items: flex-start;
}