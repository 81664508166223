.itemContainer {
  border-radius: 20px;
  box-shadow: 2px 4px 30px 0px rgba(26, 41, 60, 0.1);
  margin-bottom: 20px;
}

.blueText {
  color: var(--primary-blue);
}

.row {
  padding: 8px 20px;
  overflow-wrap: anywhere;
  align-items: flex-start;
}

.row:first-child {
  padding-top: 15px;
}

.row:last-child {
  padding-bottom: 15px;
}

.icon {
  width: 25px;
}

.value {
  color: #1d1d1f;
}