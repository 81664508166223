// All global styling for tables should go here.

$table-border-radius: 20px;

table {
  border-spacing: 0;
  border-radius: $table-border-radius;
  border-collapse: inherit;
  box-shadow: 0px 4px 30px 0px rgba(26, 41, 60, 0.04);
}

.table {
  margin-bottom: 1.75rem;
}

th {
  font-weight: 600;
}

thead tr:first-child > th:first-child {
  border-top-left-radius: $table-border-radius;
  padding-left: 28px;
}

thead tr:first-child > th:last-child {
  border-top-right-radius: $table-border-radius;
}

tbody tr:last-child > td:first-child {
  border-bottom-left-radius: $table-border-radius;
}

tbody tr:last-child > td:last-child {
  border-bottom-right-radius: $table-border-radius;
}

tbody td:first-child {
  padding-left: 28px;
}

// Add dark styling that is shown in the current CoreUI docs (v5) but is not
// available in our current version (< v5).
.table-dark {
  --cui-table-color: #fff;
  --cui-table-bg: #1d1d1f; // Custom color from our design
  --cui-table-border-color: #4d515a;
  --cui-table-striped-bg: #2c313b;
  --cui-table-striped-color: #fff;
  --cui-table-active-bg: #373c46;
  --cui-table-active-color: #fff;
  --cui-table-hover-bg: #323640;
  --cui-table-hover-color: #fff;
}
