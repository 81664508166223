.container {
  height: 100%;
  border-radius: 20px;
  box-shadow: 2px 4px 30px 0px rgba(26, 41, 60, 0.1);
  padding: 22px;
}

.blueBox {
  border-radius: 20px;
  border: 1px solid #3267f1;
  background: #f5fbff;
  padding: 13px 15px;
}

.photoContainer {
  border-radius: 16px;
  background: #fff;
  padding: 16px;
}

.roundButton {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 37px;
  height: 37px;
}

.icon {
  height: 16px;
  width: 16px;
}

.animalName {
  color: #1d1d1f;
  font-size: 24px;
  font-weight: 700;
}

.badge {
  width: 88px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(50, 103, 241, 0.1);
  color: var(--primary-blue);
  border-radius: 10px;
  border: 1px solid var(--primary-blue);
  font-weight: 400;
  font-size: 14px;
  padding: 4px 10px;
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4;
  justify-content: space-between;
}

.column {
  width: 100%;
}

.column:first-child {
  border-right: 1px solid #ececec;
  padding-right: 33px;
}

.column:last-child {
  padding-left: 26px;
}

.columnHeader {
  color: #1d1d1f;
  font-size: 20px;
  font-weight: 700;
}

.value {
  color: #1d1d1f;
  font-weight: 500;
  word-break: break-word;
  text-align: end;
}

.fullWidth {
  width: 100%;
}

.moreButton {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  width: 37px;
  height: 37px;
}

.moreIcon {
  height: 19px;
  width: 19px;
}
