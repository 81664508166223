.selectedPrice {
  color: var(--primary-blue);
  padding: 8px;
  border-radius: 100px;
  background-color: var(--background-blue);
  font-weight: bold;
}

.price {
  padding: 8px;
}

.rejectedText {
  text-decoration: line-through;
}

.dragSvg {
  color: gray;
}