.mainPage {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sidePanel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 4px 30px 0px rgba(26, 41, 60, 0.1);
  min-width: 300px;
  width: 350px;
  overflow-y: scroll;
}

.panelMainContent {
  height: 100vh;
  padding: 25px;
  gap: 15px;
  display: flex;
  flex-direction: column;
}