.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;    
  max-width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0px 4px 30px 0px rgba(26, 41, 60, 0.06);
  background-color: white;
}

@media (min-width: 992px) {
  .container {
    width: 75%;
  }
}

.accordionToggle {
  background-color: #FBFBFB;
  border-radius: 20px !important; // todo
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.accordionToggle:hover {
  background-color: var(--background-blue);
}

.accordionToggle.active {
  border: 1px solid var(--primary-blue);
  background-color: var(--background-blue);
}

.accordionToggle > button::after {
  display: none;
}

.accordionToggleText {
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
}
.accordionToggleText > svg {
  width: 18px;
}

.accordionToggle.active > .accordionToggleText {
  color: var(--primary-blue);
  font-weight: bold;
}

.animalPreview {
  display: flex;
  gap: 5px;
}

.animalDetail {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.healthPlanButton {
  border-radius: 15px;
  border: 1px solid var(--light-gray);
  background-color: white;
  width: 49%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.healthPlanButton:hover {
  background-color: var(--background-blue);
}

.healthPlanButton.active {
  border: 1px solid var(--primary-blue);
  background-color: var(--background-blue);
}

.forwardBookingDetails {
  border-radius: 15px;
  border: 1px solid var(--light-gray);
  padding: 15px;
  margin-bottom: 20px;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.forwardBookingTitle {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.dueDatePill {
  border-radius: 40px;
  padding: 5px 10px;
  height: min-content;
  text-align: center;
  background-color: var(--light-gray);
}

.healthPlanButton.active > .dueDatePill {
  border: 1px solid var(--primary-blue);
  color: var(--primary-blue);
  background-color: transparent;
}

.dateTimeDoctorText {
  display: flex;
  align-items: center;
  gap: 3px;
}

.datePicker {
  border: 1px solid var(--primary-blue);
  border-radius: 20px;
}
.datePicker table > thead > tr > th:first-child {
  padding: 0px;
}
.datePicker table > tbody > tr > td:first-child {
  padding: 0px;
}

.notesSection {
  display: flex;
  flex-direction: column;
  gap: 30px;

  padding: 20px;
  border-radius: 20px;
  background: #FBFBFB;
}

.fees {
  text-align: right;
  font-size: 20px;
  font-weight: bold;
  margin: 0px;
}