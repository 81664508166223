.header {
  margin-bottom: 15px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  border-radius: 20px;
  box-shadow: 2px 4px 30px 0px rgba(26, 41, 60, 0.1);
  padding: 5px 10px;
  margin: 0px 0px 10px 0px;
  width: 100%;
}

.clientAnimalSection {
  border: 1px solid var(--blue-primary, #3267f1);
  background: #f5fbff;
  padding: 20px;
}

.animalSection {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.infoGroup {
  border-radius: 16px;
  background: #fff;
  padding: 6px;
}

.internalNotes {
  background: var(--background-pink);
  border: 1px solid var(--primary-pink);
  padding: 15px 10px 15px 20px;
  border-radius: 20px;
  font-weight: normal;
  flex: 1 0 auto;
  width: -webkit-fill-available;
}

.scrollableContent {
  overflow-y: scroll;
  height: -webkit-fill-available;

  p {
    margin-bottom: 0px;
  }
}

.name {
  font-weight: bold;
  font-size: 20px;
  color: #1d1d1f;
  cursor: pointer;
  text-decoration: none;
}

.customerRow {
  padding: 4px 8px;
  display: flex;
  gap: 10px;
}

.infoRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--light-gray);
  padding: 5px 10px;

  &:last-child {
    border-bottom: none;
  }
}

.infoRow > div:first-child {
  font-weight: bold;
}

.infoRow > div:last-child {
  text-align: right;
}