.whiteboard {
  thead tr:first-child > th:first-child {
    padding-left: 0px;

    table {
      width: 100% !important;
    }
  }
  tbody td:first-child {
    padding-left: 0px;

    table {
      width: 100% !important;
    }
  }

  .fc-timeline-event-harness {
    bottom: 0px;
  }
}

.infoRow {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
}

.actionButton {
  width: -webkit-fill-available;
  height: fit-content;
  white-space: nowrap;
}

.navLink {
  display: flex !important;
  align-items: center;
  gap: 3px;
}

.navLink > svg {
  height: 15px;
  width: 15px;
}

.sidebarBody {
  padding: 0px 20px;
}

.groupRow {
  text-transform: capitalize;
}

.eventCell {
  height: 25px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  cursor: pointer;

  border: 2px solid #3267F1;
  background-color: #F5FBFF;
  color: black;
}

.cellSuccess {
  background-color: #E7FBEE;
  border-color: #42D573;
}

.cellWarning {
  border-color: #FF1A56;
  background-color: #ff1a561a;
}

.cellPlain {
  background-color: transparent;
  border: none;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-wrap: break-word;
}

.sheetContainer {
  visibility: hidden;
}
.sheetContainer.active {
  visibility: visible;
}