.header {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.container {
  border-radius: 20px;
  border: 1px solid var(--blue-primary, #3267f1);
  background: #f5fbff;
  padding: 15px;
  margin: 0px;
}

.headerRow {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.whiteGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  flex-grow: 1;
  border-radius: 16px;
  background: #fff;
  padding: 15px;
  gap: 6px;
}

.photo {
  width: 120px;
  height: auto;
  object-fit: cover;
}

.name {
  font-weight: bold;
  font-size: 20px;
  color: #1d1d1f;
  cursor: pointer;
  text-decoration: none;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 15px;
}

.icon {
  margin-right: 10px;
  width: 20px;
  height: 18px;

  path {
    stroke: var(--primary-blue);
  }

  &.unstroked path {
    stroke: none;
  }
}

.value {
  font-weight: 600;
  text-transform: capitalize;
  text-align: right;
}
