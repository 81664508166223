.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.header {
  border-radius: 15px;
  border: 1px solid var(--primary-blue);
  background: var(--background-blue);
  padding-top: 15px;
}

.planDetails {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0px 15px;
}

.confirmSection {
  border-top: 1px solid var(--primary-blue);
  background-color: rgba(50, 103, 241, 0.1);
  border-radius: 0px 0px 15px 15px;
  padding: 15px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 13px;
}

.cancelCheckmarks {
  background-color: white;
  border-radius: 15px;
  border: 1px solid var(--primary-blue);
  padding: 15px;
}

.reasonsContainer {
  display: flex;
  flex-wrap: wrap;
}

.checkmark {
  max-width: 50%;
  width: 50%;
  text-align: left;
}

.requiredText {
  color: var(--cui-danger);
  font-weight: 500;
  text-transform: capitalize;
}

.requiredInput {
  border-color: var(--cui-danger);
}

.buttonRow {
  display: flex;
  gap: 15px;
}

.body {
  border-radius: 15px;
  border: 1px solid var(--primary-blue);
  background: white;
  padding: 15px;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.bottomBorder {
  border-bottom: 1px solid var(--light-gray);
  padding-bottom: 15px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 15px 0px;
}

.noPadding {
  padding: 0px;
}

.column {
  display: flex;
  flex-direction: column;
  width: auto;
}

.left {
  display: flex;
  align-items: center;
  gap: 6px;
}

.right {
  font-weight: 500;
  text-align: right;
}

.badge {
  background-color: white;
  padding: 15px;
  border-radius: 16px;
}

.pinkText {
  color: var(--primary-pink);
}

.blueText {
  color: var(--primary-blue);
}

.cancelReasons {
  font-weight: 500;
}

.membershipTable {
  width: 100%;
}

.membershipHistoryButton {
  > button {
    background-color: var(--background-blue) !important;
    border-radius: 15px;
  }
}
