.root {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--primary-blue);
  border-radius: 20px;
  padding: 25px;
  gap: 10px;
  background: var(--background-blue);
  position: relative;
  width: 66%;
  height: min-content;
}

.pulse {
  position: absolute;
  top: 25%;
  right: 0px;
  pointer-events: none;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column {
  display: flex;
  align-items: center;
  width: auto;
  gap: 9px;
}

.header {
  width: auto;
  margin: 0;
}

.badge {
  width: auto;
  display: flex;
  align-items: center;
  border: 1px solid var(--primary-pink);
  color: var(--primary-pink);
  border-radius: 10px;
  background: white; 
  font-weight: 600;
  font-size: 14px;
  padding: 10px;
  margin-right: 10px;
}

.link {
  color: #3267F1;
  font-weight: 500;
}

.lineText {
  margin: 0px;
  font-weight: 500;
  line-height: 37px;
}

.blueText {
  margin: 0px;
  font-weight: 500;
  color: var(--primary-blue);
  line-height: 37px;
}

.editButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 37px;
  height: 37px;
}

.moreButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  width: 37px;
  height: 37px;
}

.moreIcon {
  height: 19px;
  width: 19px;
}
