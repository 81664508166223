.memoList {
  display: flex;
  border: 1px solid #ececec;
  border-radius: 10px;
  width: 100%;
  max-height: 80vh;
  min-height: 80vh;
  margin-bottom: 20px;
}

.clamped {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

// LEFT PANE

.leftPane {
  width: 35%;
  min-width: 35%;
  max-width: 35%;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #ececec;
  background-color: #ececec;
  padding: 10px 0px;
  overflow: scroll;
}

.leftHeader {
  margin: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  margin: 0px;
  margin-left: 15px;
}

// Memo Preview

.memo {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  margin: 0px 10px;
}

.memo:hover {
  background-color: white;
}

.expanded {
  background-color: white;
}

.highlightedText {
  font-weight: bold;
}

.date {
  color: #8f8f8f;
  font-size: smaller;
  display: flex;
  align-content: center;
  align-self: flex-end;
  gap: 10px;
  font-weight: lighter;
  white-space: nowrap;
}

.subject {
  white-space: pre-line;
  font-weight: bold;
}

.read .subject {
  font-weight: 400;
}

.read .highlightedText {
  font-weight: 600;
}

.memoDetails {
  width: -webkit-fill-available;
}

// RIGHT PANE

.rightPane {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: -webkit-fill-available;
  padding: 0px;
}

// Expanded memo

.memoHeader {
  border-bottom: 1px solid #ececec;
  padding: 10px;
  margin-bottom: 10px;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.memoRepliesWrapper {
  overflow:auto;
  display:flex;
  flex-direction:column-reverse; 
  height: 100%;
}

.memoRepliesList {
  display: flex;
  flex-flow: column;
  gap: 10px;
  padding: 0px 20px;
  overflow-anchor: none;
}

#anchor {
  overflow-anchor: auto;
  height: 1px;
}

.memoContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}

.contentSender {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
}

.sender {
  gap: 5px;
  display: flex;
  align-items: center;
}

.contentRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.contentBody {
  background-color: #ececec;
  border-radius: 10px;
  padding: 10px;
  width: 70%;
}

.me {
  align-items: flex-end;
}

.me .contentSender {
  flex-direction: row-reverse;
}

.me .contentRow {
  justify-content: flex-end;
}

.me .contentBody {
  background-color: var(--primary-blue);
  color: white;
}

// Reply section

.replySection {
  margin-top: 10px;
  padding: 0px 20px 10px;
}

.replyTo {
  margin-bottom: 10px;
}
.replyToCallout {
  margin: 0px;
  border-top: none;
  border-bottom: none;
  border-right: none;
  padding: 5px 10px;
}
.replyToText {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}