.header {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.container {
  border-radius: 20px;
  border: 1px solid #3267f1;
  background: #f5fbff;
  padding: 15px 12px 0px;
}

.photoNameContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 16px;
  background: #fff;
  padding: 6px;
}

.photoContainer {
  position: relative;
  height: 80px;
  width: 80px;
}

.uploadButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;

  width: 28px;
  height: 28px;
  top: -12px;
  right: -12px;
}

.spinner {
  height: 12px;
  width: 12px;
}

.name {
  font-weight: bold;
  font-size: 20px;
  color: #1d1d1f;
  cursor: pointer;
  text-decoration: none;
}

.icon {
  margin-right: 10px;
  width: 18px;
  height: 18px;

  path {
    stroke: var(--primary-blue);
  }

  &.unstroked path {
    stroke: none;
  }
}

.value {
  font-weight: 600;
  text-align: end;
}

.patientNotes {
  background-color: white;
  border-top: 1px solid rgba(50, 103, 241, 0.20);
  padding: 15px 0px 0px;
  border-radius: 0px 0px 30px 30px;
}

.notesContent {
  white-space: pre-wrap;
}