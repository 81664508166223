.detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-bottom: 1px solid var(--light-gray);
}

.left {
  display: flex;
  align-items: center;
  gap: 6px;
}

.right {
  font-weight: 500;
  text-align: right;
}