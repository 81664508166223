.appointmentNotes {
  background: var(--background-pink);
  border: 1px solid var(--primary-pink);
  padding: 15px 10px 15px 20px;
  border-radius: 20px;
  font-weight: normal;
  width: -webkit-fill-available;
}

.scrollableContent {
  overflow-y: scroll;
}
