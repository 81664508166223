.header {
  margin-bottom: 15px;
}

.container {
  display: flex;
  gap: 20px;

  border-radius: 20px;
  box-shadow: 2px 4px 30px 0px rgba(26, 41, 60, 0.1);
  padding: 5px 10px 10px;
  margin: 0px;
}

.healthPlansSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  margin-top: 5px;
  gap: 10px
}

.infoRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid var(--light-gray);
  padding: 10px;

  &:last-child {
    border-bottom: none;
  }
}

.label {
  text-align: start;
  width: 50%;
}

.notes {
  border: 1px solid var(--light-gray);
  border-radius: 15px;
  padding: 10px;
}

.notesHeader {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  margin-right: 10px;
  width: 20px;
  height: 18px;

  path {
    stroke: var(--primary-blue);
  }

  &.unstroked path {
    stroke: none;
  }
}