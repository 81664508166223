.appointments {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 48%;
  padding: 10px;
}

.animal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.name {
  font-weight: bold;
  font-size: 20px;
  color: #1d1d1f;
  cursor: pointer;
  text-decoration: none;
}

.reasons {
  width: 100%;
}