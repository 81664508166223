.root {
  border-radius: 20px;
  background: white;
  box-shadow: 2px 4px 30px 0px rgba(26, 41, 60, 0.1);
  display: flex;
  flex-direction: column;
  width: max-content;
  min-width: -webkit-fill-available;
  max-width: fit-content;
}

.header {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 11px;
  padding: 13px 20px;
  border-bottom: 1px solid #ececec;
}

.dateSection {
  display: flex;
  flex-direction: column;
  padding: 13px 20px;
  gap: 13px;
}

.appointmentsSection {
  border-radius: 15px;
  border: 1px solid var(--primary-blue);
  background: var(--background-blue);
  padding: 16px 13px;
  gap: 13px;
  display: flex;
  flex-direction: column;
  margin: 0px 20px 20px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 11px;
}

.column {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: auto;
  gap: 6px;
  width: 100%;
}

.patientImage {
  border: 4px solid rgba(50, 103, 241, 0.1);
}

.patientName {
  margin: 0;
  display: flex;
  justify-content: space-between;
  gap: 11px;
  width: 100%;
}

.right {
  font-weight: 500;
}
