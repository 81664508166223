.documentRoot {
  table {
    border-radius: 0px;
    border: none;
  }

  thead tr:first-child > th:first-child {
    padding-left: 0px;
  }

  tbody td:first-child {
    padding-left: 5px;
  }

  td {
    vertical-align: top;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 0px !important;
  }
}
