.estimateDocumentTable {
  width: 100%;
  margin-bottom: 40px;
  box-shadow: none;

  tr {
    height: 30px;
  }

  td {
    border: none !important;
    padding: 0px !important;
    vertical-align: middle !important;
  }
}

.tableHeader {
  th {
    border-bottom: 1px solid gray;
  }
}