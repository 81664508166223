.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  margin-top: unset;
  margin-bottom: 0;

  padding-top: 6px;
  padding-bottom: 6px;

  @media (max-width: 1399.98px) {
    margin-top: 20px;
  }
}

.textarea {
  border: 1px solid var(--primary-pink);
  padding: 15px;
  border-radius: 20px;
  overflow-y: scroll;
  height: 195px;
}

.animalNotes {
  background: var(--background-pink);
  border: 1px solid var(--primary-pink);
  padding: 15px;
  border-radius: 20px;
  font-weight: normal;
  min-height: 195px;
  white-space: pre-line;
}

.editButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 37px;
  height: 37px;
}