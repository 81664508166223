.wrapper {
  margin-bottom: 25px;
}

.dates {
  margin-bottom: 20px;
}

.date {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.dateInWords {
  font-weight: 500;
}

.column {
  width: 50%;
}

.icon {
  height: 16px;
  width: 16px;
  margin-right: 9px;
}

.icon path {
  stroke: var(--primary-blue);
}

.label {
  display: flex;
  align-items: center;
  width: 95px;
  color: #545454;
}

.value {
  color: #1d1d1f;
}

.communicationWrapper {
  border-radius: 16px;
  padding: 15px;
  background: #f8fcff;
}

.communicationContext {
  border-radius: 16px;
  border: 0.5px solid #3267f1;
  background: #fff;
  padding: 17px 18px 14px 18px;
  height: fit-content;
}

.toFrom {
  border-bottom: 1px solid #ececec;
}

.contextItem {
  margin-top: 1rem;
}

.contextItem:last-child {
  margin-bottom: 0px;
}

.communication {
  padding: 5px 0px 5px 18px;
  white-space: break-spaces;
}

.communicationHeader {
  font-weight: 700;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.editButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 25px;
  height: 25px;
}