.root {
  border-radius: 20px;
  background: white;
  box-shadow: 2px 4px 30px 0px rgba(26, 41, 60, 0.10);
  display: flex;
  flex-direction: column;
  width: max-content;
  min-width: -webkit-fill-available;
  max-width: fit-content;
}

.cardDetails {
  border-radius: 15px;
  border: 1px solid var(--primary-blue);
  background: var(--background-blue);
  gap: 13px;
  margin: 0px 10px 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  padding: 10px 20px;
}

.left {
  display: flex;
  align-items: center;
  gap: 6px;
}

.right {
  font-weight: 500;
  text-align: right;
}

.topRow {
  border-bottom: 1px solid var(--light-gray);
  font-weight: bold;
  padding: 20px 20px 10px;
}

.highlightedRow {
  color: var(--primary-pink);
}