.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.textAreaWrapper {
  flex: 1 0 auto;
}

.textarea {
  border: 1px solid var(--primary-pink);
  padding: 15px 10px 15px 20px;
  border-radius: 20px;
  width: '100%';
}

.customerNotes {
  background: var(--background-pink);
  border: 1px solid var(--primary-pink);
  padding: 15px 10px 15px 20px;
  border-radius: 20px;
  font-weight: normal;
  flex: 1 0 auto;
  white-space: pre-line;
}

.editButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 37px;
  height: 37px;
  margin-bottom: 2px;
}
