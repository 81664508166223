.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 50%;
}

.header {
  margin-bottom: 0;

  padding-top: 6px;
  padding-bottom: 6px;
}

.textarea {
  border: 1px solid var(--primary-pink);
  padding: 15px;
  border-radius: 20px;
  overflow-y: scroll;
  height: 100%;
}

.appointmentNotes {
  background: var(--background-pink);
  border: 1px solid var(--primary-pink);
  padding: 15px;
  border-radius: 20px;
  font-weight: normal;
  height: 100%;
  white-space: pre-line;
}

.editButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 37px;
  height: 37px;
}