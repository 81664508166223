.table {
  box-shadow: 0px 4px 30px 0px rgba(26, 41, 60, 0.06);
}

.form {
  border-radius: 20px;
  box-shadow: 0px 4px 30px 0px rgba(26, 41, 60, 0.1);
  padding: 21px;
}

.buttons {
  gap: 10px;
}

.button {
  min-width: 127px;
}

.required {
  color: var(--primary-blue);
}
