// Adjust page and sidebar layout
.wrapper {
  width: 100%;
  will-change: auto;
  @include transition(padding 0.15s);
}

:root {
  --fc-today-bg-color: #ffffff;
  --primary-blue: #3267f1;
  --primary-pink: #ff1a56;
  --background-blue: #f5fbff;
  --background-pink: rgba(219, 126, 150, 0.1);
  --light-gray: #ececec;
  --cui-heading-color: #1d1d1f;

  // Lighten and embolden the event titles for FullCalendar background events (planning guides). Colors chosen for
  // scheduling categories will need to be dark for these titles to be legible.
  .fc {
    & .fc-bg-event {
      & .fc-event-title {
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
}

// Start: Global color classes for styling icons, text, etc.
.primaryColor {
  color: var(--cui-primary);
}
.dangerColor {
  color: var(--cui-danger);
}
.successColor {
  color: var(--cui-success);
}

.whiteColor {
  color: #ffffff;
}
// End: Global color classes

.form-control,
.input-group-text {
  --cui-border-radius: 16px;
  --cui-input-group-addon-border-color: #ececec;
  --cui-input-border-color: #ececec;
}

[id^='discount'],
.form-control {
  padding: 0.375rem 0.35rem 0.375rem 0.45rem;
}

.picker-input-group.input-group {
  --cui-border-radius: 16px;

  .input-group-text {
    padding: 0.375rem 0.75rem 0.375rem 0.1rem;
  }
}

.form-control:disabled {
  border: 1px solid #ececec;
  background: rgba(217, 217, 217, 0.5);
}

// Use Dr. Treat brand color for components that accept a background color.
// Example: https://coreui.io/react/docs/components/avatar/
.bg-brand {
  background: #fe1c56;
}
.bg-brand-subtle {
  color: #fe1c56;
  background-color: var(--background-pink);
}

// Change badge colors to have a light background and dark text
.bg-primary {
  background: rgba(var(--cui-primary-rgb), 0.1) !important;
  color: $primary;
}

.bg-success {
  background: rgba(var(--cui-success-rgb), 0.1) !important;
  color: $success;
}

.bg-danger {
  background: rgba(var(--cui-danger-rgb), 0.1) !important;
  color: $danger;
}

.bg-warning {
  background: rgba(var(--cui-warning-rgb), 0.1) !important;
  color: $warning;
}

.bg-secondary {
  background: rgba(var(--cui-secondary-rgb), 0.1) !important;
  color: $secondary;
}

.bg-dark {
  background: rgba(var(--cui-dark-rgb), 0.1) !important;
  color: $dark;
}

.bg-info {
  background: rgba(var(--cui-info-rgb), 0.1) !important;
  color: $info;
}

// Turn off the 'shake' animation when the static modal is clicked. Allows
// to use other modals while the static modal is open.
.modal-dialog {
  .modal.modal-static & {
    transform: none;
  }
}

// Make the sidebar font bigger than the rest of the page at 14px.
.sidebar {
  font-size: 16px;
}

// Style the paginator buttons.
.page-link {
  display: flex;
  justify-content: center;

  margin-right: 1rem;
  border-radius: 10px;
  background: #fff;
  box-shadow: 2px 4px 30px 0px rgba(26, 41, 60, 0.1);
  width: 36px;
  height: 36px;
}

// Remove the underline effect from underlined nav links.
.nav-underline {
  border-bottom: none;
}

// Replace the underline effect under non-active nav links.
.nav-underline .nav-link {
  border-bottom: 2px solid #545454;
}

// Be consistent with the active nav link coloring on hover.
.nav-underline .nav-link:hover {
  color: $nav-pills-link-active-bg;
}

// Override TinyMCE focused border radius, which seems to assume use of the statusbar.
.tox .tox-edit-area::before {
  border-radius: 8px !important;
}

// Override FullCalendar's cell bottom gutter for timeline views
.fc .fc-timeline-overlap-enabled .fc-timeline-lane-frame .fc-timeline-events {
  padding-bottom: 0;
}

.fc .fc-timeline-event {
  margin-bottom: 0;
}

.fc .fc-datagrid-cell-cushion {
  padding: 0px 10px;
}

.fc .fc-resource .fc-datagrid-cell-frame {
  align-content: center;
}

.fc-resource .fc-icon {
  display: none;
}
.fc-resource .fc-datagrid-expander {
  display: none;
}

// Hide up/down arrows on number inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  appearance: textfield; /* Standard */
  -moz-appearance: textfield; /* Firefox */
}

.calendar-cell {
  padding-left: 0 !important;
}
